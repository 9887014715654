import React from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW } from '../../styles/index'
import Icons from '../../utils/Icons'

interface Props {
  starsQuantity: number
  totalStars: number
}

export default function StarsReview(props: Props) {

  const {
    starsQuantity,
    totalStars,
  } = props

  const stars = Array(starsQuantity).fill('potato')

  return (
    <Wrapper>
      {
        stars.map((_, index)=>(
          index < starsQuantity
          ?
          <Icons 
            name="starFilled"
            key={index}
          />
          :
          <Icons
            name="starEmpty"
            key={index}
          />
        ))
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${desktopBreakpoint}{
    width: ${desktopVW(90)};
  }
`;