import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex, MobileView, DesktopView } from '../../styles/index'
import { SettingsContext } from '../../contexts'
import ProductOOSEmailInput from './ProductOOSEmailInput'
import { decode } from 'shopify-gid'
import qs from 'qs'
import {useGlobalComponentsQuery, useGlobalDictionaryQuery} from '../../hooks/index'
import Icons from '../../utils/Icons'
import {useSpring, animated, config} from 'react-spring'

interface Props {
  shopifyData: {
    price: string
    compareAtPrice: string
    outOfStock: boolean
  }
  selectedVariant: {
    price: string
    title: string
    outOfStock: string
    compare_at_price: string
    id: string
    product_id: string
  }
  setSelectedVariantState: any
  productTitle: string
  updateVariants: any
}

export default function ProductOOSEmail(props: Props) {

  const {
    shopifyData,
    selectedVariant,
    setSelectedVariantState,
    productTitle,
    updateVariants,
  } = props

  const {
    isCartOpen,
    setIsCartOpen
  }: {
    isCartOpen: any
    setIsCartOpen: any
  } = useContext(SettingsContext)

  const {
    klaviyoNewsletterListId
  }: {
    klaviyoNewsletterListId: string
  } = useGlobalComponentsQuery()

  const [quantity, setQuantity] = useState(1)
  const [desktopSelectOpen, setdesktopSelectOpen] = useState(false)
  const [desktopSelectState, setDesktopSelectState] = useState(selectedVariant.title)

  const handleSelectVariant = e => {
    setSelectedVariantState(...shopifyData.variants.filter(({title})=>title === e.target.value))
  }
  const dictionary = useGlobalDictionaryQuery();
  const [email, setEmail] = useState('')
  const [containErrors, SetContainErrors] = useState(false)
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState(dictionary?.submit);

  const handleContainerrors = state => {
    if (state) {
      SetContainErrors(true)
    }
    if (!state) {
      SetContainErrors(false)
    }
  }

  const reset = ()=>{setLoading(false); setEmail(''); setText(dictionary?.submit)}

  function submit() {
    if(containErrors) return
    setText(dictionary?.loading)
    let variantDecoded = decode(selectedVariant.id)
    let idDecoded = decode(selectedVariant.product_id)
    const requestOptions = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify({
        a:process.env.KLAVIYO_API_KEY,
        email: email,
        g: klaviyoNewsletterListId,
        variant: variantDecoded.id,
        product: idDecoded.id,
        platform: 'shopify',
        subscribe_for_newsletter: false,
      }),
    }
    fetch(
      'https://a.klaviyo.com/onsite/components/back-in-stock/subscribe',
      requestOptions,
    ).then(response => {
      setTimeout(()=>{
        if (response.status === 200) {
          setText(dictionary?.backInStockSuccessfulSubmission)
          setTimeout(()=>reset(), 3000)
          return
        } else if (response.status !== 200) {
          setText(dictionary?.backInStockFailedSubmission)
          setTimeout(()=>reset(), 3000)
        }
      }, 1500)
    })
  }

  

  const submitEmail = async e => {
    e.preventDefault()
    setLoading(true)
    setTimeout(()=>{
      submit()
    }, 500)
}

const handleSizeclickDesktop = sizeTitle=>{
  setSelectedVariantState(...shopifyData.variants.filter(({title})=>title === sizeTitle))
  setDesktopSelectState(sizeTitle)
  setdesktopSelectOpen(false)
}

const selectSpring = useSpring({
  transform: desktopSelectOpen ? 'translateY(0%)' : 'translateY(-100%)',
})

  return (
    <Wrapper
      onSubmit={e=>submitEmail(e)}
    >
      <MobileView>
        <SizeSelect>
          <label htmlFor="sizes">Size</label>
          <VariantSelect 
            name="sizes" 
            id="sizes"
            value={selectedVariant.title}
            onChange={handleSelectVariant}
            onClick={()=>updateVariants()}
          >
            {
              shopifyData.variants.map(({title})=>(
                <option 
                  value={title}
                  key={title}
                >
                  {title}
                </option>
              ))
            }
          </VariantSelect>
          <IconWrapper>
            <Icons
              name={desktopSelectOpen ? 'arrowUp' : 'arrowDown'}
            />
          </IconWrapper>
        </SizeSelect>
      </MobileView>
      <DesktopView>
        <SizeSelect>
          <label htmlFor="sizes">Size</label>
          <VariantSelectHeaderDesktop
            onClick={()=>{
              updateVariants()
              setTimeout(()=>setdesktopSelectOpen(!desktopSelectOpen), 300)
            }}
          >
           {desktopSelectState}
          </VariantSelectHeaderDesktop>
          <IconWrapper
            onClick={()=>{
              updateVariants()
              setTimeout(()=>setdesktopSelectOpen(!desktopSelectOpen), 300)
            }}
          >
            <Icons
              name={desktopSelectOpen ? 'arrowUp' : 'arrowDown'}
            />
          </IconWrapper>
          <SelectOptionsDesktop>
            <animated.div
              style={{...selectSpring, willChange: 'transform'}}
            >
              {
                shopifyData.variants.map(({title})=>(
                  <OptionChildDesktop 
                    key={title}
                    onClick={()=>handleSizeclickDesktop(title)}
                  >
                    <p>{title}</p>
                  </OptionChildDesktop>
                ))
              }
            </animated.div>
          </SelectOptionsDesktop>
        </SizeSelect>
      </DesktopView>
      <OOSEmailWrapper>
        <TitleBlock>
          <p>{dictionary.outOfStock}</p>
        </TitleBlock>
        <InfoBlock>
          <ProductOOSEmailInput
            inputValue={email}
            inputSetValue={setEmail}
            inputName='email'
            inputType='text'
            required={true}
            onChangecallback={e => setEmail(e.target.value)}
            placeholder='Email'
            color={colors.black}
            width={mobileVW(350)}
            widthDesk={desktopVW(300)}
            fontSizeDesk={desktopVW(14)}
            fontSize={mobileVW(18)}
            validation={'onlyEmail'}
            setContainErrors={handleContainerrors}
          />
        </InfoBlock>
        <ButtonBlock
          isButton={email !== ''}
          isButtonActive={containErrors}
        >
          {
            email === ''
            ?
            <p>{dictionary?.notifyWhenBackInStock}</p>
            : 
            <button
              type='submit'
              aria-label="submit email"
              //disabled={containErrors}
            >{text}</button>
          }
          
        </ButtonBlock>
      </OOSEmailWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  
`;

const VariantSelect = styled.select`
  color: ${colors.black};
  width: auto;
  position: absolute;
  top: 0;
  right: ${mobileVW(30)};
  font-family: "messinaMono", "Courier New", Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  font-size: ${mobileVW(18)};
  border: none;
  //outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent !important;

  option{
    text-align: right;
  }

  ${desktopBreakpoint} {
    width: ${desktopVW(160)};
    margin-left: ${desktopVW(10)};
    font-size: ${desktopVW(14)};
    cursor: pointer;
  }
`

const SizeSelect = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "messinaMono", "Courier New", Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  border-bottom: ${mobileVW(1)} solid ${colors.lightGrey};
  padding-bottom: ${mobileVW(10)};
  margin-bottom: ${mobileVW(30)};
  position: relative;

  label{
    font-size: ${mobileVW(18)};
    ${desktopBreakpoint}{
      font-size: ${desktopVW(14)};
    }
  }

  ${desktopBreakpoint}{
    border-bottom: ${desktopVW(1)} solid ${colors.lightGrey};
    padding-bottom: ${desktopVW(10)};
    padding-right: ${desktopVW(25)};
    margin-bottom: ${desktopVW(20)};
    font-size: ${desktopVW(14)};
  }
`;





const OOSEmailWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas: "title" "info" "button";
  grid-template-columns: 100%;
  grid-template-rows: ${mobileVW(90)} ${mobileVW(90)} ${mobileVW(90)};
  border: ${mobileVW(1)} solid ${colors.subtleGrey};


  ${desktopBreakpoint}{
    border: ${desktopVW(1)} solid ${colors.subtleGrey};
    grid-template-rows: ${desktopVW(74)} ${desktopVW(74)} ${desktopVW(74)};
  }
`;

const TitleBlock = styled.div`
  grid-area: title;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "messinaMono", "Courier New", Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.frappuccino};
  background-color: ${colors.black};
  font-size: ${mobileVW(18)};

  ${desktopBreakpoint}{
    font-size: ${desktopVW(14)};

  }
`;

const InfoBlock = styled.div`
  grid-area: info;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "messinaMono", "Courier New", Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  font-size: ${mobileVW(18)};
  border-bottom: ${mobileVW(1)} solid ${colors.black};

  ${desktopBreakpoint}{
    font-size: ${desktopVW(14)};
    border-bottom: ${desktopVW(1)} solid ${colors.black};
  }
`;

const ButtonBlock = styled.div`
  grid-area: button;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({isButton})=>isButton ? colors.frappuccino : colors.black};
  background-color: ${({isButton})=>isButton ? colors.black : 'transparent'};

  button, p{
    font-family: "messinaMono", "Courier New", Courier, monospace;
    letter-spacing: -0.05em;
    font-size: ${mobileVW(18)};
    ${desktopBreakpoint}{
      font-size: ${desktopVW(14)};
    }
  }

  button{
    width: 100%;
    height: 100%;
    cursor: ${({isButton})=>isButton ? 'pointer' : 'default'};
  }

`;

const Counter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  

`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  width: 40%;
  height: 100%;
  cursor: pointer;

`;

const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  

  ${desktopBreakpoint}{
    width: 10%;
    height: 100%;
  }
`;

const VariantSelectHeaderDesktop = styled.div`
  color: ${colors.black};
  width: auto;
  font-family: "messinaMono", "Courier New", Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  border: none;
  font-size: ${desktopVW(14)};
  cursor: pointer;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding-top: ${mobileVW(4)};

    ${desktopBreakpoint}{
      right: ${desktopVW(-5)};
      padding-top: 0;
      cursor: pointer;
    }
`;

const SelectOptionsDesktop = styled(animated.div)`
  position: absolute;
  top: ${desktopVW(26)};
  right: 0;
  width: auto;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  overflow: hidden;
  z-index: ${zIndex.surface + 2};
`;

const OptionChildDesktop = styled.div`
  user-select: none;
  pointer-events: all;
  position: relative;
  color: ${colors.frappuccino};
  height: ${desktopVW(55)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${desktopVW(25)};
  background-color: ${colors.black};
  z-index: ${zIndex.surface + 2};
  cursor: pointer;
  border-bottom: ${desktopVW(1)} solid ${colors.lightGrey};

  p{
    pointer-events: none;
  }
`;