import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSpring, animated, config } from 'react-spring';
import { decode } from 'shopify-gid';
import {
  desktopBreakpoint,
  colors,
  desktopVW,
  mobileVW,
  zIndex,
  MobileView,
  DesktopView,
} from '../../styles/index';
import { SettingsContext } from '../../contexts';
import { useGlobalDictionaryQuery } from '../../hooks/index';
import formatPrice from '../../utils/formatPrice';
import ProductFormSkylt from './ProductFormSkylt';
import Icons from '../../utils/Icons';

import {
  useAddItemToCart,
  useAddToCartTracking,
  useProductViewTracking,
  useLayout,
  useToggleCart,
} from '../../hooks';

interface Props {
  shopifyData: {
    price: string;
    compareAtPrice: string;
    outOfStock: boolean;
  };
  skyltChecked: any;
  setSkyltChecked: any;
  selectedVariant: {
    price: string;
    title: string;
    outOfStock: string;
    compare_at_price: string;
    id: string;
  };
  setSelectedVariantState: any;
  productTitle: string;
  updateVariants: any;
  quantity: any;
  setQuantity: any;
  category: string;
  allowAddProtection: boolean;
  protectionProduct: any;
}

export default function ProductForm(props: Props) {
  const {
    shopifyData,
    skyltChecked,
    setSkyltChecked,
    selectedVariant,
    setSelectedVariantState,
    productTitle,
    updateVariants,
    quantity,
    setQuantity,
    category,
    allowAddProtection,
    protectionProduct,
  } = props;

  const {
    isCartOpen,
    setIsCartOpen,
    purchaseAllowed,
  }: {
    isCartOpen: any;
    setIsCartOpen: any;
    purchaseAllowed: any;
  } = useContext(SettingsContext);

  const dictionary = useGlobalDictionaryQuery();

  const [desktopSelectOpen, setdesktopSelectOpen] = useState(false);
  const [desktopSelectState, setDesktopSelectState] = useState(selectedVariant.title);

  const selectSpring = useSpring({
    transform: desktopSelectOpen ? 'translateY(0%)' : 'translateY(-102%)',
  });

  const handleSelectVariant = e => {
    setSelectedVariantState(
      ...shopifyData.variants.filter(({ title }) => title === e.target.value)
    );
  };

  const handleSizeclickDesktop = sizeTitle => {
    setSelectedVariantState(...shopifyData.variants.filter(({ title }) => title === sizeTitle));
    setDesktopSelectState(sizeTitle);
    setdesktopSelectOpen(false);
  };

  const addItemToCart = useAddItemToCart();

  const handleAddToCart = async () => {
    // If skylt is selected, pass {key: "skylt", value: "true"} as customAttributes
    // customAttribtues format [{key: "foo", value: "bar"}, {key: "foo2", value: "bar2"}]
    if (allowAddProtection) {
      await addItemToCart(selectedVariant.id, quantity, [
        {
          key: `Extra protection - ${protectionProduct.title}`,
          value: skyltChecked ? 'added' : 'not added',
        },
      ]);
    } else {
      await addItemToCart(selectedVariant.id, quantity);
    }
    useAddToCartTracking(
      selectedVariant.id,
      `${productTitle} - ${selectedVariant.title}`,
      parseFloat(selectedVariant.price),
      location,
      quantity,
      category
    );
    if (!isCartOpen) {
      setIsCartOpen(true);
    }
  };

  useEffect(() => {
    if (selectedVariant.outOfStock !== null && selectedVariant.id) {
      useProductViewTracking(
        selectedVariant.id,
        `${productTitle} - ${selectedVariant.title}`,
        parseFloat(selectedVariant.price),
        location,
        category
      );
    }
  }, [selectedVariant]);
  return (
    <Wrapper>
      <MobileView>
        <SizeSelect>
          <label htmlFor="sizes">{dictionary?.pdpSizeText}</label>
          <VariantSelect
            name="sizes"
            id="sizes"
            value={selectedVariant.title}
            onChange={handleSelectVariant}
            onClick={() => updateVariants()}
          >
            {shopifyData.variants.map(({ title }) => (
              <option value={title} key={title}>
                {title}
              </option>
            ))}
          </VariantSelect>
          <IconWrapper>
            <Icons name={desktopSelectOpen ? 'arrowUp' : 'arrowDown'} />
          </IconWrapper>
        </SizeSelect>
      </MobileView>
      <DesktopView>
        <SizeSelect onMouseLeave={() => setTimeout(() => setdesktopSelectOpen(false), 150)}>
          <label htmlFor="sizes">{dictionary?.pdpSizeText}</label>
          <VariantSelectHeaderDesktop
            onClick={() => {
              updateVariants();
              setTimeout(() => setdesktopSelectOpen(!desktopSelectOpen), 150);
            }}
          >
            {desktopSelectState}
          </VariantSelectHeaderDesktop>
          <IconWrapper
            onClick={() => {
              updateVariants();
              setTimeout(() => setdesktopSelectOpen(!desktopSelectOpen), 150);
            }}
          >
            <Icons name={desktopSelectOpen ? 'arrowUp' : 'arrowDown'} />
          </IconWrapper>
          <SelectOptionsDesktop>
            <animated.div style={{ ...selectSpring, willChange: 'transform' }}>
              {shopifyData.variants.map(({ title }) => (
                <OptionChildDesktop key={title} onClick={() => handleSizeclickDesktop(title)}>
                  <p>{title}</p>
                </OptionChildDesktop>
              ))}
            </animated.div>
          </SelectOptionsDesktop>
        </SizeSelect>
      </DesktopView>
      <AddToCartWrapper allowAddProtection={allowAddProtection}>
        {allowAddProtection && (
          <ProductFormSkylt
            skyltChecked={skyltChecked}
            setSkyltChecked={setSkyltChecked}
            price={Number(protectionProduct.price)}
          />
        )}
        <QuantityBlock>{dictionary?.pdpQuantityText}</QuantityBlock>
        <QuantityCounterBlock>
          <Counter onMouseEnter={() => updateVariants()}>
            <Button
              type="button"
              aria-label="decrease quantity"
              onClick={() => (quantity > 1 ? setQuantity(quantity - 1) : null)}
            >
              -
            </Button>
            <Count>{quantity}</Count>
            <Button
              type="button"
              aria-label="increase quantity"
              onClick={() => (quantity < 10 ? setQuantity(quantity + 1) : null)}
            >
              +
            </Button>
          </Counter>
        </QuantityCounterBlock>
        <AddToCartBlock>
          {purchaseAllowed &&
            (purchaseAllowed.allowed ? (
              <button type="button" aria-label="add to cart" onClick={() => handleAddToCart()}>
                {`${formatPrice(
                  selectedVariant.price && skyltChecked
                    ? selectedVariant.price * quantity + Number(protectionProduct.price) * quantity
                    : selectedVariant.price
                    ? selectedVariant.price * quantity
                    : 0,
                  'Rp.'
                )}
                   - ${dictionary?.addToCartText}`}
              </button>
            ) : (
              <DeniedMsg>{dictionary?.noPurchaseMessage}</DeniedMsg>
            ))}
        </AddToCartBlock>
      </AddToCartWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SizeSelect = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  border-bottom: ${mobileVW(1)} solid ${colors.lightGrey};
  padding-bottom: ${mobileVW(10)};
  margin-bottom: ${mobileVW(30)};
  position: relative;

  label {
    font-size: ${mobileVW(18)};
    ${desktopBreakpoint} {
      font-size: ${desktopVW(14)};
    }
  }

  ${desktopBreakpoint} {
    border-bottom: ${desktopVW(1)} solid ${colors.lightGrey};
    padding-bottom: ${desktopVW(10)};
    padding-right: ${desktopVW(25)};
    margin-bottom: ${desktopVW(20)};
    font-size: ${desktopVW(14)};
  }
`;

const AddToCartWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas: ${props =>
    props.allowAddProtection
      ? `"skyltBlock skyltBlock" "quantity quantityCounter" "addtoCart addtoCart"`
      : `"quantity quantityCounter" "addtoCart addtoCart"`};
  grid-template-columns: 40% 60%;
  grid-template-rows: ${props =>
    props.allowAddProtection
      ? `${mobileVW(73)} ${mobileVW(90)} ${mobileVW(90)}`
      : `${mobileVW(90)} ${mobileVW(90)}`};
  ${desktopBreakpoint} {
    grid-template-rows: ${props =>
      props.allowAddProtection
        ? `${desktopVW(53)} ${desktopVW(74)} ${desktopVW(74)}`
        : `${desktopVW(74)} ${desktopVW(74)}`};
  }
`;

const VariantSelect = styled.select`
  color: ${colors.black};
  width: auto;
  position: absolute;
  top: 0;
  right: ${mobileVW(30)};
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  font-size: ${mobileVW(18)};
  border: none;
  //outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent !important;

  option {
    text-align: right;
  }

  ${desktopBreakpoint} {
    width: ${desktopVW(160)};
    margin-left: ${desktopVW(10)};
    font-size: ${desktopVW(14)};
    cursor: pointer;
  }
`;

const VariantSelectHeaderDesktop = styled.div`
  color: ${colors.black};
  width: auto;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  border: none;
  font-size: ${desktopVW(14)};
  cursor: pointer;
`;

const SelectOptionsDesktop = styled(animated.div)`
  position: absolute;
  top: ${desktopVW(27.5)};
  right: 0;
  width: auto;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  overflow: hidden;
`;

const OptionChildDesktop = styled.div`
  user-select: none;
  pointer-events: all;
  position: relative;
  color: ${colors.frappuccino};
  height: ${desktopVW(55)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${desktopVW(25)};
  background-color: ${colors.black};
  z-index: ${zIndex.surface + 2};
  cursor: pointer;
  border-bottom: ${desktopVW(1)} solid ${colors.lightGrey};

  p {
    pointer-events: none;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding-top: ${mobileVW(4)};

  ${desktopBreakpoint} {
    right: ${desktopVW(-5)};
    padding-top: 0;
    cursor: pointer;
  }
`;

const QuantityBlock = styled.div`
  grid-area: quantity;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  font-size: ${mobileVW(18)};
  border-left: ${mobileVW(1)} solid ${colors.lightGrey};
  border-top: ${mobileVW(1)} solid ${colors.lightGrey};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
    border-left: ${desktopVW(1)} solid ${colors.lightGrey};
    border-top: ${desktopVW(1)} solid ${colors.lightGrey};
  }
`;

const QuantityCounterBlock = styled.div`
  grid-area: quantityCounter;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  font-size: ${mobileVW(18)};
  border-left: ${mobileVW(1)} solid ${colors.lightGrey};
  border-right: ${mobileVW(1)} solid ${colors.lightGrey};
  border-top: ${mobileVW(1)} solid ${colors.lightGrey};

  ${desktopBreakpoint} {
    border-left: ${desktopVW(1)} solid ${colors.lightGrey};
    border-right: ${desktopVW(1)} solid ${colors.lightGrey};
    border-top: ${desktopVW(1)} solid ${colors.lightGrey};
    font-size: ${desktopVW(14)};
  }
`;

const AddToCartBlock = styled.div`
  grid-area: addtoCart;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  font-size: ${mobileVW(18)};

  button {
    width: 100%;
    height: 100%;
    background-color: ${colors.black};
    color: ${colors.cappuccino};
    font-family: 'messinaMono', 'Courier New', Courier, monospace;
    letter-spacing: -0.05em;
    font-size: ${mobileVW(18)};
    ${desktopBreakpoint} {
      font-size: ${desktopVW(14)};
    }
  }

  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
  }
`;

const Counter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  width: 40%;
  height: 100%;
  cursor: pointer;
`;

const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${desktopBreakpoint} {
    width: 10%;
    height: 100%;
  }
`;

const DeniedMsg = styled.div`
  width: 100%;
  height: 100%;
  border-top: ${mobileVW(1)} solid ${colors.lightGrey};
  font-size: ${mobileVW(18)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 ${mobileVW(20)};

  ${desktopBreakpoint} {
    border-top: ${desktopVW(1)} solid ${colors.lightGrey};
    font-size: ${desktopVW(14)};
    padding: 0 ${desktopVW(20)};
  }
`;
